import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { GroupSettingsProvider, useGroupSettingsContext } from './GroupSettingsContext';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { MembersAutocompleteField } from './MembersAutocompleteField';
import { CloseButton } from './CloseButton';
import { useCallback } from 'react';
import { useDialogLoader } from '../../../common/dialogs/DialogLoader';

interface ManageMembersDialogProps {
  onClose: () => void;
  onSave: (assignmentGroupData: Partial<AssignmentGroup>) => void | Promise<void>;
}

const DIALOG_CONTENT_WIDTH = 852;

const ManageMembersDialog = ({ onClose, onSave }: ManageMembersDialogProps) => {
  const { assignmentGroup } = useGroupSettingsContext();
  const { DialogLoader, showLoader, hideLoader } = useDialogLoader();

  const saveChanges = useCallback(async () => {
    showLoader();
    await onSave(assignmentGroup as Partial<AssignmentGroup>);
    hideLoader();
    onClose();
  }, [assignmentGroup, hideLoader, onClose, onSave, showLoader]);

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '.MuiDialogTitle-root': {
          paddingBottom: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">Manage members of {assignmentGroup.name}</Typography>
          <CloseButton onClick={onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ width: DIALOG_CONTENT_WIDTH }}>
        <MembersAutocompleteField />
      </DialogContent>
      <DialogActions>
        <Button onClick={saveChanges}>Save changes</Button>
      </DialogActions>
      {DialogLoader}
    </Dialog>
  );
};

const ManageMembersDialogWithProviders = ({
  onClose,
  onSave,
  assignmentGroup,
}: ManageMembersDialogProps & { assignmentGroup: AssignmentGroup }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  if (!crmOrgId) {
    return null;
  }
  return (
    <GroupSettingsProvider crmOrgId={crmOrgId} initialAssignmentGroup={assignmentGroup}>
      <ManageMembersDialog onClose={onClose} onSave={onSave} />
    </GroupSettingsProvider>
  );
};
export { ManageMembersDialogWithProviders as ManageMembersDialog };
